<template>
  <BaseDialog :model-value="modal.status" size-class="!max-w-xl" @update:model-value="close">
    <template #header>
      <div class="flex items-center space-x-0.5">
        <div>
          <span v-if="viewOnly && editableAddress?.label">{{ editableAddress?.label }}</span>
          <span v-else>{{ addressObject ? 'Update' : 'Create' }} {{ payload.name }}</span>
        </div>
        <div v-if="editableAddress.address" class="pl-2 text-sm text-gray-400">
          <span v-if="editableAddress?.address?.length && editableAddress?.address?.length < 10">{{
            editableAddress.address
          }}</span>
          <span v-else>
            {{ truncate(editableAddress.address, 4)
            }}{{ editableAddress.address.slice(editableAddress.address?.length - 3) }}
          </span>
        </div>
        <ClipboardDocumentIcon
          class="h-4 w-4 cursor-pointer text-gray-600 hover:text-white"
          @click="copyToClipboard(editableAddress.address)"
        />
      </div>
    </template>
    <template #default>
      <div class="flex-col items-center space-y-2 px-1 py-4">
        <div class="space-y-2" v-if="headerLabel == 'Address' && !folderOnly">
          <div class="text-sm font-medium text-gray-100">Address<span class="pl-1 text-red-500">*</span></div>
          <BaseTextbox
            placeholder="Enter Label"
            v-model="editableAddress.address"
            maxlength="30"
            :disabled="viewOnly"
          />
          <span v-if="addressError" class="text-xs text-red-400">{{ addressError }}</span>
        </div>
        <div v-if="!folderOnly">
          <div class="text-sm font-medium text-gray-100">Label<span class="pl-1 text-red-500">*</span></div>
          <BaseTextbox placeholder="Enter Label" v-model="editableAddress.label" maxlength="30" :disabled="viewOnly" />
        </div>
        <span v-if="labelError" class="text-xs text-red-400">{{ labelError }}</span>
        <div class="space-y-2 pb-6" v-if="!viewOnly">
          <div class="text-sm font-medium text-gray-100">Folders</div>
          <BaseSelect
            :multiple="true"
            :searchable="true"
            :placeholder="'Select Folder'"
            class="!absolute w-[90%]"
            :options="folderOptions"
            v-model="editableAddress.watchlist_ids"
            button-width-class="w-full truncate"
            dropdown-width-class="w-full"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <div
        class="flex w-full space-x-2 px-2"
        :class="addressObject ? 'justify-between' : 'justify-end'"
        v-if="!viewOnly"
      >
        <div class="flex space-x-2" :class="!(addressObject && !viewOnly) ? 'w-full' : ''">
          <BaseButton type="danger" v-if="addressObject && !viewOnly" @press="deleteAddressLabel()">
            Delete
          </BaseButton>
          <BaseButton class="create-button" :busy="buttonBusy" :disabled="disabled" @press="createFolder()"
            >Create Folder</BaseButton
          >
        </div>
        <div class="flex items-center space-x-2">
          <BaseButton :disabled="buttonBusy" type="secondary" @press="close"> Cancel </BaseButton>
          <BaseButton class="create-button" :busy="buttonBusy" :disabled="buttonBusy" @press="createAddressLabel()">{{
            addressObject ? 'Update' : 'Create'
          }}</BaseButton>
        </div>
      </div>
    </template>
  </BaseDialog>
</template>

<script setup>
import { useStore } from 'vuex';
import uniqBy from 'lodash/uniqBy';
import { ref, computed, watch } from 'vue';
import { truncate } from '@/composeables/filters';
import { useWatchlistStore } from '@/stores/watchlists';
import { copyToClipboard } from '@/composeables/helpers';
import { ClipboardDocumentIcon } from '@heroicons/vue/20/solid';
import { createAddresses, updateAddress, deleteAddress } from '@/api/address-api';

const store = useStore();
const $watchlistStore = useWatchlistStore();
const $emit = defineEmits(['close']);

// USER ID
const userId = computed(() => {
  return store.getters.session?.user?.id;
});

// FOLDER
const teamFolders = computed(() => {
  let folders = $watchlistStore.teams.onchainFolders;
  return folders.filter(
    i => i.user_entity_permissions?.find(j => j.user_id == userId.value && j.can_write) || i.user_id == userId.value
  );
});
const folderOptions = computed(() => {
  return uniqBy(
    [$watchlistStore.onchainFolders.filter(i => i.user_id == userId.value), teamFolders.value]?.flat()?.map(x => {
      return { id: x.id, label: x.name };
    }),
    'id'
  );
});

const otherUsersFolders = ref([]);

//ADDRESS
const onchainAddresses = computed(() => {
  return store.getters.onchainAddresses;
});
const editableAddress = ref({
  address: '',
  label: '',
  watchlist_ids: []
});
const labelError = ref('');
const addressError = ref('');
const buttonBusy = ref(false);
const disabled = ref(false);

async function createAddressLabel() {
  let otherAddresses = onchainAddresses.value.filter(x => x.id != editableAddress.value.id);
  let labelFounded = otherAddresses.find(
    x => x.label == editableAddress.value.label && x.address != editableAddress.value.address
  );
  if (otherAddresses.find(x => x.address == editableAddress.value.address)) {
    addressError.value = 'Address already exist.';
  } else if (editableAddress.value.label.trim().length == 0) {
    labelError.value = 'Please provide label.';
  } else if (editableAddress.value.address.trim().length == 0) {
    addressError.value = 'Please provide address.';
  } else if (labelFounded) {
    labelError.value = 'Label already exist.';
  } else if (
    otherAddresses.find(
      x =>
        x.label == editableAddress.value.label &&
        x.address == editableAddress.value.address &&
        editableAddress.value.watchlist_ids.some(item => x.watchlist_ids.includes(item))
    )
  ) {
    labelError.value = 'Address with this label already exists.';
  } else {
    buttonBusy.value = true;
    if (addressObject.value) {
      editableAddress.value.watchlist_ids = [otherUsersFolders.value, ...editableAddress.value.watchlist_ids]?.flat();
      await updateAddress(editableAddress.value);
    } else {
      await createAddresses({ addresses: [editableAddress.value] });
    }
    store.dispatch('setOnchainAddresses');
    buttonBusy.value = false;
    close();
  }
}
async function deleteAddressLabel() {
  const id = editableAddress.value.id;
  store.dispatch('confirm', 'Please confirm you want to delete the address mapping?').then(async () => {
    await deleteAddress(id);
    store.dispatch('setOnchainAddresses');
  });
  close();
}
watch(
  () => editableAddress.value,
  () => {
    if (editableAddress.value.label && editableAddress.value.label.trim().length != '') {
      labelError.value = '';
      disabled.value = false;
    }
  }
);

//MODAL
const modal = computed(() => {
  return store.getters.onchainAddressesModal;
});

const payload = computed(() => {
  return modal.value.payload || {};
});

const headerLabel = computed(() => {
  return payload.value.name || 'Label';
});

const folderOnly = computed(() => {
  return payload.value?.folderOnly || false;
});

const viewOnly = computed(() => {
  return payload.value?.selectedAddressObj?.id
    ? payload.value?.selectedAddressObj?.user_id != userId.value &&
        !store.getters.roles?.includes('admin') &&
        !store.getters.roles?.includes('super_admin')
    : false;
});

const addressObject = computed(() => {
  return payload.value?.selectedAddressObj || null;
});
watch(
  () => payload.value,
  () => {
    if (payload.value?.selectedAddressObj) {
      editableAddress.value = payload.value?.selectedAddressObj;
      otherUsersFolders.value =
        payload.value?.selectedAddressObj?.watchlist_ids?.filter(
          x => !folderOptions.value.map(y => y.id).includes(x)
        ) || [];
      editableAddress.value.watchlist_ids = folderOptions.value
        .filter(x => editableAddress.value.watchlist_ids.includes(x.id))
        ?.map(x => x.id);
    } else if (payload.value?.selectedAddress) {
      editableAddress.value.address = payload.value.selectedAddress;
    }
  }
);

const close = () => {
  editableAddress.value = {
    address: '',
    label: '',
    watchlist_ids: []
  };
  otherUsersFolders.value = [];
  store.commit('setOnchainAddressesModal', { open: false, payload: {} });
};

const createFolder = () => {
  store.commit('setOnchainFolderModal', {
    status: true,
    payload: {}
  });
};
</script>
